<template>
  <div
    class="light-paragraph ql-editor"
    v-html="safeContent"
  />
</template>

<script>
import xss from 'xss'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  props: {
    content: String
  },
  computed: {
    safeContent () {
      return xss(this.content)
    }
  }
}
</script>
